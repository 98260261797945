.toggle-soft .toggle-slide {
  border-radius: 5px;
  box-shadow: 0 0 0 1px #999;
}
.toggle-soft .toggle-on,
.toggle-soft .toggle-off {
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  text-shadow: 1px 1px white;
}
.toggle-soft .toggle-on,
.toggle-soft .toggle-select .toggle-inner .active {
  background: -webkit-linear-gradient(#d2ff52, #91e842);
  background: linear-gradient(#d2ff52, #91e842);
}
.toggle-soft .toggle-off,
.toggle-soft .toggle-select .toggle-on {
  background: -webkit-linear-gradient(#cfcfcf, #f5f5f5);
  background: linear-gradient(#cfcfcf, #f5f5f5);
}
.toggle-soft .toggle-blob {
  border-radius: 4px;
  background: -webkit-linear-gradient(#cfcfcf, #f5f5f5);
  background: linear-gradient(#cfcfcf, #f5f5f5);
  box-shadow: inset 0 0 0 1px #bbb, inset 0 0 0 2px white;
}
.toggle-soft .toggle-blob:hover {
  background: -webkit-linear-gradient(#e4e4e4, #f9f9f9);
  background: linear-gradient(#e4e4e4, #f9f9f9);
  box-shadow: inset 0 0 0 1px #ddd,inset 0 0 0 2px #ddd;
}
